<template>
  <v-card>
    <v-card-title>
      <span class="headline">Detalle de Nota de llamada</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="detallenota">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="fromData.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromData.date"
              label="Fecha de hoy"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <!-- ESTO POR SI ACASO LUEGO QUIEREN CAMBIAR Y PONERLE QUE PUEDE ACTUALIZAR -->
          <!--     <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker> -->
        </v-menu>
        <v-text-field
          v-model="fromData.descripcion"
          :rules="[(v) => !!v || 'El dato es requerido']"
          label="Descripcion"
        >
        </v-text-field>
        <v-autocomplete
          :items="entities"
          label="Ejecutivo."
          dense
          search
          clearable
          v-model="fromData.identities"
        ></v-autocomplete>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-flex text-right>
        <v-btn text color="success" @click="guardarNota">Aceptar</v-btn>
        <v-btn text color="red" @click="setDialogLlamadas(false)">
          Cancelar
        </v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  mixins: [funcion],
  props: { id: Number },
  data() {
    return {
      menu: false,

      fromData: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        identities: JSON.parse(localStorage.getItem("datos")).id_entitie,
      },
    };
  },
  mounted() {
    this.getEntities();
  },
  methods: {
    ...mapMutations([
      "setEntities",
      "setDialogLlamadas",
      "setRegistroLlamadaFlag",
    ]),
    async getEntities() {
      let data = {
        id_module: 6,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getModulesEntities",
        data
      );
      let entities = [];
      valores.forEach((element) => {
        entities.push({
          value: element.id_entitie,
          text: element.name,
        });
      });

      this.setEntities(entities);
    },

    async guardarNota() {
      if (this.$refs.detallenota.validate()) {
        let data = {
          id_quote: this.idQuote,
          id_operador: this.fromData.identities,
          comentario: this.fromData.descripcion,
          fecha: this.fromData.date,
        };

        await this.obtenerDataApi(
          "post",
          process.env.VUE_APP_URL_MAIN + "setCalls",
          data
        );
        this.dialog = false;
        this.setDialogLlamadas(false);
        this.$store.state.flag = !this.$store.state.flag;
        this.setRegistroLlamadaFlag();
      }
    },
  },
  computed: {
    ...mapState(["entities", "idQuote", "flag"]),
  },
};
</script>

<style></style>
